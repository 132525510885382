import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import createQuery from "astrid-firestore/src/helpers/createQuery";

export default function useOrdersQuery({ sent, deleted = false, firstPartyId, expense, processed, secondPartyId }) {
	return useMemo(
		() =>
			createQuery(db.collection("orders"), {
				sent,
				deleted,
				expense,
				processed,
				"firstParty.id": firstPartyId,
				"secondParty.id": secondPartyId,
			}),
		[sent, deleted, expense, processed, firstPartyId, secondPartyId],
	);
}
