import { Header } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import { useProfile } from "../../authentication/state/profile";
import OrdersAccordion from "../../orders/components/OrdersAccordion/OrdersAccordion";
import useOrdersQuery from "../../orders/hooks/useOrdersQuery";

export default function ListAccountPurchaseOrders() {
	const { t } = useTranslation();
	const profile = useProfile();

	const [orders = [], loading, error] = useCollectionData(
		useOrdersQuery({ expense: true, secondPartyId: profile?.id || null })
			.orderBy("created", "desc")
			.limit(100),
	);

	return (
		<>
			<Header inline>{t("invoiceData", "Invoice data")}</Header>

			<LoadingContext data={orders} loading={loading} error={error}>
				<OrdersAccordion orders={orders} />
			</LoadingContext>
		</>
	);
}
