import runBatch from "../../helpers/runBatch";

import createOrderData from "../orders/createOrderData";

import createCost from "./createCost";
import summarizeOpenCosts from "./summarizeOpenCosts";

export default async function finalizeOpenCosts(firebase, openCosts) {
	const db = firebase.firestore();

	await runBatch(db, async (batch) => {
		for (const { openAmount, ...costData } of openCosts) {
			const cost = createCost(db, {
				...costData,
				amount: openAmount,
			});

			// If the cost is a reader cost, create an order for it.
			if (cost.price?.type === "reader") {
				const order = createOrderData(firebase, {
					costs: [cost],
				});

				batch.set(order.ref, { ...order, production: cost.production });
				batch.set(cost.ref, { ...cost, orderId: order.id });
			} else {
				batch.set(cost.ref, cost);
			}
		}

		const productionIds = [...new Set(openCosts.map((cost) => cost.production.id))];

		await Promise.all(
			productionIds.map(async (productionId) => {
				const productionRef = db.collection("productions").doc(productionId);
				const openCostsSummary = await summarizeOpenCosts(firebase, productionRef);
				// trigger scheduled job to generate statistics.
				const statisticsGenerated = false;

				batch.update(productionRef, { openCostsSummary, statisticsGenerated });
			}),
		);
	});
}
