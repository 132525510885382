import { Dropdown, Icon } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import useTodoHeaderTranslations from "../../../../todos/hooks/useTodoHeaderTranslations";
import Popup from "../../../../ui/components/Popup/Popup";

export default function TodoTypeDropdown({ type, todos, onClick }) {
	const { t } = useTranslation();

	const todoTypeTranslations = useTodoHeaderTranslations();

	const options = [...new Set(todos.map((todo) => todo.type))].map((type) => ({
		key: type,
		value: type,
		text: todoTypeTranslations[type],
	}));

	return (
		<Dropdown
			icon={false}
			floating
			trigger={
				<Popup
					content={type ? todoTypeTranslations[type] : t("filterByType", "Filter by type")}
					trigger={<Icon color={type && "blue"} link name="eye" />}
				/>
			}
		>
			<Dropdown.Menu>
				<Dropdown.Item text={t("all", "All")} onClick={() => onClick(null)} />
				{options.map((option) => (
					<Dropdown.Item
						key={option.key}
						text={option.text}
						onClick={() => onClick(option.value)}
						active={type === option.value}
						style={{
							color: type === option.value && "#2185d0",
							fontWeight: type === option.value && "normal",
						}}
					/>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
}
