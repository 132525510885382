import { Button, Grid } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import Message from "../../../ui/components/Messages/Message";

import Vendor from "../../../vendors/components/Vendor";

import OrderCosts from "./components/OrderCosts";

export default function OrderDetails({ order }) {
	const { t } = useTranslation();

	const billedVendor = order.expense ? order.firstParty?.vendor : order.secondParty?.vendor;
	const senderVendor = order.expense ? order.secondParty?.vendor : order.firstParty?.vendor;

	const onMarkAsSent = () => {
		updateDocument(order.ref, { sent: true });
	};

	return (
		<>
			<Grid celled>
				{order.firstParty?.id === "HfvG4FQfXWpWv6dzqM5E" && (
					<Grid.Row>
						<Grid.Column>
							<Message info header={order.id}>
								{t(
									"includePurchaseOrderIdMessage",
									"Include the purchase order reference in the invoice",
								)}
							</Message>
						</Grid.Column>
					</Grid.Row>
				)}
				<Grid.Row columns={2}>
					{billedVendor && (
						<Grid.Column>
							<strong>{t("recipient", "Recipient")}</strong>

							<Vendor vendor={billedVendor} />
						</Grid.Column>
					)}
					{senderVendor && (
						<Grid.Column>
							<strong>{t("sender", "Sender")}</strong>

							<Vendor vendor={senderVendor} />
						</Grid.Column>
					)}
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<OrderCosts order={order} />
					</Grid.Column>
				</Grid.Row>
				{!order.sent && (
					<Grid.Row>
						<Grid.Column>
							<Button floated="right" primary onMouseDown={onMarkAsSent}>
								{t("markAsSent", "Mark as sent")}
							</Button>
						</Grid.Column>
					</Grid.Row>
				)}
			</Grid>
		</>
	);
}
