import { Dropdown, Icon } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import { languageName } from "astrid-helpers/src/languages";

import Popup from "../../../../ui/components/Popup/Popup";

export default function TodoProductionLanguageDropdown({ productionLanguage, todos, onClick }) {
	const { t } = useTranslation();

	const options = todos
		.flatMap((todo) => {
			if (todo.languages?.length) {
				return todo.languages?.map((language) => ({
					key: language,
					value: language,
					text: languageName(language),
				}));
			} else {
				return [];
			}
		})
		.filter((option, index, arr) => arr.findIndex((t) => t.value === option.value) === index);

	const selectedLanguage = options.find((option) => option.value === productionLanguage);

	return (
		<Dropdown
			icon={false}
			floating
			trigger={
				<Popup
					content={selectedLanguage?.text || t("filterByProductionLanguage", "Filter by production language")}
					trigger={<Icon color={selectedLanguage && "blue"} link name="language" />}
				/>
			}
		>
			<Dropdown.Menu>
				<Dropdown.Item text={t("all", "All")} onClick={() => onClick(null)} />
				{options.map((option) => {
					return (
						<Dropdown.Item
							active={selectedLanguage?.text === option.text}
							key={option.key}
							text={option.text}
							onClick={() => onClick(option.value)}
							style={{
								color: selectedLanguage?.text === option.text && "#2185d0",
								fontWeight: selectedLanguage?.text === option.text && "normal",
							}}
						/>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);
}
