import { Message } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import db from "astrid-firebase/src/db";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import useCollectionCount from "astrid-firestore/src/hooks/useCollectionCount";

import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";

import { useProfile } from "../../../authentication/state/profile";
import { useOrganizationType } from "../../../organizations/state/organizationType";

export default function AccountSidebar() {
	const { t } = useTranslation();

	const profile = useProfile();
	const orgType = useOrganizationType();

	const [costsAmount] = useCollectionCount(
		db
			.collection("costs")
			.where("secondParty.id", "==", profile?.id)
			.where("processed", "==", false)
			.where("expense", "==", true)
			.where("orderId", "==", null)
			.where("deleted", "==", false)
			.where("created", ">=", new Date("2025-01-23")),
	);

	const options = useMemo(
		() => [
			{
				path: "vendor-settings",
				name: t("vendorSettings", "Billing information"),
			},
			{
				path: "revenues",
				name: t("revenues", "Revenues"),
				label: costsAmount || undefined,
				hidden: orgType === organizationTypes.NARRATOR,
			},
			{
				path: "orders",
				name: t("invoiceData", "Invoice data"),
				hidden: orgType === organizationTypes.NARRATOR,
			},
			{
				path: "agreements",
				name: t("agreements", "Agreements"),
			},
			{
				path: "settings",
				name: t("settings", "Settings"),
			},
		],
		[costsAmount, orgType, t],
	);

	return (
		<>
			<Message>
				<Message.Header>
					{profile.firstName} {profile.lastName}
				</Message.Header>
			</Message>
			<VerticalMenu options={options} />
		</>
	);
}
