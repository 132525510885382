import Table from "../../../../ui/components/Table/Table";

import useAccountCostsColumns from "../hooks/useAccountCostsColumns";

import AccountCostsTableActions from "./AccountCostsTableActions";

export default function AccountCostsTable(props) {
	const costsColumns = useAccountCostsColumns();

	return (
		<Table
			sortable
			filterable
			pagination
			selectable
			columns={costsColumns}
			footer={<AccountCostsTableActions {...props} />}
			sortBy={{
				id: "created",
				desc: true,
			}}
			{...props}
		/>
	);
}
