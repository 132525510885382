import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Notifications from "../../../notifications/components/Notifications/Notifications";
import useNotifications from "../../../notifications/components/Notifications/hooks/useNotifications";

import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

import TodoClearFiltersIcon from "../TodosWidget/components/TodoClearFiltersIcon";
import TodoOrderIcon from "../TodosWidget/components/TodoOrderIcon";
import TodoProductionLanguageDropdown from "../TodosWidget/components/TodoProductionLanguageDropdown";
import TodoUserDropdown from "../TodosWidget/components/TodoUserDropdown";

export default function NotificationsWidget({ organization }) {
	const { t } = useTranslation();

	const [productionLanguage, setProductionLanguage] = useState(null);
	const [userId, setUserId] = useState(null);
	const [order, setOrder] = useState("desc");

	const { filteredNotifications, sortedNotifications, loading, error } = useNotifications({
		productionLanguage,
		order,
		userId,
		organization,
	});

	const activeFilters = productionLanguage || userId || order === "asc";

	const onClear = () => {
		setProductionLanguage(null);
		setUserId(null);
		setOrder("desc");
	};

	return (
		<HomeLayout.Widget
			icon="list ul"
			header={t("notifications", "Notifications")}
			toolbar={
				<>
					<TodoProductionLanguageDropdown
						productionLanguage={productionLanguage}
						todos={sortedNotifications}
						onClick={setProductionLanguage}
					/>

					<TodoUserDropdown userId={userId} todos={sortedNotifications} onClick={setUserId} />

					<TodoOrderIcon
						order={order}
						onClick={() => setOrder((order) => (order === "desc" ? "asc" : "desc"))}
					/>

					<TodoClearFiltersIcon onClick={onClear} active={activeFilters} />
				</>
			}
		>
			<Notifications
				notifications={filteredNotifications}
				loading={loading}
				error={error}
				organization={organization}
			/>
		</HomeLayout.Widget>
	);
}
