import uniqid from "uniqid";

import round from "astrid-helpers/src/round";

import { getUserRef } from "../../utils/referenceMappers";

const prefixMap = {
	po: "PO-",
	so: "SO-",
};

function calculateVat({ costs }) {
	return costs.reduce((acc, cost) => {
		const vat = cost.price.vat || 0;
		const previousTotal = acc?.[vat];
		if (previousTotal) {
			return {
				...acc,
				[vat]: round(previousTotal + cost.totalVat, 2),
			};
		}
		return {
			...acc,
			[vat]: cost.totalVat,
		};
	}, {});
}

function sumVat({ vatPerPercentage }) {
	return round(
		Object.values(vatPerPercentage).reduce((acc, value) => acc + value, 0),
		2,
	);
}

export default function createOrderData(
	firebase,
	{ costs = [], sent = false, deleted = false, processed = false, createdBy = null },
) {
	const db = firebase.firestore();

	const { expense, price, firstParty, secondParty } = costs[0];
	const { currency } = price;

	const total = round(
		costs.reduce((total, cost) => total + cost.total, 0),
		2,
	);

	const vatPerPercentage = calculateVat({ costs });
	const totalVat = sumVat({ vatPerPercentage });
	const totalIncVat = round(total + totalVat, 2);
	const totalAmountProduced = costs.reduce((total, cost) => total + cost.amountProduced, 0);

	const prefix = prefixMap[expense ? "po" : "so"];
	const id = uniqid(prefix).toUpperCase();
	const ref = db.collection("orders").doc(id);

	return {
		id: ref.id,
		ref,
		sent,
		total,
		totalVat,
		totalIncVat,
		totalAmountProduced,
		vatPerPercentage,
		deleted,
		expense,
		currency,
		processed,
		firstParty,
		secondParty,
		created: new Date(Date.now()),
		createdBy: getUserRef(createdBy),
	};
}
