import { useMemo } from "react";

import { useCollectionData } from "astrid-firestore/src/hooks/index";

import { useProfile } from "../../../../authentication/state/profile";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import useLanguageOptions from "../../../../users/hooks/useLanguageOptions";

import useNotificationsQuery from "../../../hooks/useNotificationsQuery";

function filterProductionLanguage(language) {
	return (todo) => !language || todo.languages?.includes(language);
}

function filterAssigned(userId) {
	return (todo) => !userId || (userId === "notAssigned" ? todo.userIds.length === 0 : todo.userIds.includes(userId));
}

export default function useNotifications(
	{ productionLanguage = null, order = "desc", userId = null, organization = null },
	initialNotifications = [],
) {
	const user = useProfile();
	const hasRight = useHasRight();

	const languageOptions = useLanguageOptions({ organization });

	const [notifications = initialNotifications, loading, error] = useCollectionData(
		useNotificationsQuery({
			userId: user.id,
			organizationId: hasRight("organization.notifications.view") && organization?.id,
		}),
	);

	const sortedNotifications = useMemo(() => {
		return (order === "desc" ? notifications : notifications.slice().reverse()).filter(
			(notification) =>
				!languageOptions?.length ||
				!notification.languages ||
				notification.languages?.some((language) => languageOptions.includes(language)),
		);
	}, [languageOptions, order, notifications]);

	const filteredNotifications = useMemo(() => {
		return sortedNotifications.filter(filterAssigned(userId)).filter(filterProductionLanguage(productionLanguage));
	}, [productionLanguage, sortedNotifications, userId]);

	return { filteredNotifications, sortedNotifications, loading, error };
}
