import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import auth from "astrid-firebase/src/auth";

import { setActAsUser, useActAsUser } from "../../../../authentication/state/actAsUser";
import { useProfile } from "../../../../authentication/state/profile";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import useHasRole from "../../../../authorization/hooks/useHasRole";
import useOrganizationsOnce from "../../../../organizations/hooks/useOrganizationsOnce";
import { useOrganizationId } from "../../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../../organizations/state/organizationType";
import useGroupBy from "../../../../ui/hooks/useGroupBy";

export default function useSecondaryMenuOptions() {
	const { t } = useTranslation();

	const hasRole = useHasRole();
	const profile = useProfile();
	const navigate = useNavigate();
	const hasRight = useHasRight();
	const actAsUser = useActAsUser();
	const orgId = useOrganizationId();
	const orgType = useOrganizationType();

	const publisherIds = useMemo(() => Object.keys(profile?.permissions?.publisher || {}), [profile]);
	const publishersById = useGroupBy(useOrganizationsOnce({ ids: publisherIds }), "id");

	const producerOptions = Object.keys(profile?.permissions?.producer || {})
		.filter((id) => hasRight("menuOption.ProducerSettings") && id === orgId)
		.map((id) => ({
			to: `/settings/${id}`,
			icon: "settings",
			text: t("prodComp"),
		}));

	const publisherOptions = Object.keys(profile?.permissions?.publisher || {}).map((id, i) => ({
		to: `/publisher/${id}`,
		icon: "settings",
		text: publishersById?.[id]?.[0]?.name || `${t("profilePublisher")} (${i})`,
	}));

	return useMemo(
		() => [
			[
				{
					to: `/profile/${profile?.id}`,
					icon: "user",
					text: t("profile"),
				},
				{
					to: `/account/${profile?.id}`,
					icon: "setting",
					text: t("account", "Account"),
				},
			],

			[
				...Object.keys(profile?.permissions?.producer || {})
					.filter((id) => hasRight("menuOption.ProducerSettings") && id === orgId)
					.map((id) => ({
						to: `/settings/${id}`,
						icon: "settings",
						text: t("prodComp"),
					})),

				...Object.keys(profile?.permissions?.publisher || {}).map((id, i) => ({
					to: `/publisher/${id}`,
					icon: "settings",
					text: publishersById?.[id]?.[0]?.name || `${t("profilePublisher")} (${i})`,
				})),
			],

			[
				{
					to: `/${orgType}/${orgId}/admin`,
					icon: "settings",
					text: `${t("admin", "Admin")}`,
					hidden: !(hasRight("admin") && orgId),
				},
				{
					to: "/admin",
					icon: "spy",
					text: t("superAdmin"),
					hidden: !hasRole("owner", "admin"),
				},
			],
			[
				{
					to: "/storytool-archive",
					icon: "archive",
					text: `${t("storytoolArchive", "Storytool archive")}`,
					hidden: !profile?.permissions?.storytoolArchive?.enableArchiveAccess && !hasRole("owner", "admin"),
				},
				{
					to: "/distribution",
					icon: "sitemap",
					text: t("distribution"),
					hidden: !hasRight("distribution.editDistributors"),
				},
				{
					to: "/upload",
					icon: "upload cloud",
					text: t("uploading"),
					hidden: !hasRight("distribution.editDistributors"),
				},
				{
					to: `producer/${orgId}/documents`,
					icon: "folder open",
					text: t("documents", "Documents"),
					hidden: !(hasRight("documents.view") && orgId),
				},
				{
					to: "/statistics",
					icon: "line graph",
					text: t("statistics"),
					hidden: !hasRight("viewProductionStatistics"),
				},
			],
			[
				{
					to: "/production",
					icon: "microphone",
					text: `${t("productions")} (old)`,
					hidden: !(hasRight("listProducerProductions") || hasRight("listPublisherProductions")),
				},
				{
					to: "/computejobs",
					icon: "server",
					text: t("serverInstances"),
					hidden: !hasRole("owner"),
				},
				{
					to: "/studio-redirect",
					icon: "headphones",
					text: t("astridStudio"),
					hidden: !hasRight("studioSession.connect"),
				},
				{
					to: "/apps",
					icon: "download",
					text: t("apps"),
					hidden: !hasRight("apps.view"),
				},
			],
			[
				{
					icon: "log out",
					text: t("profileLogout"),
					hidden: !!actAsUser,
					onClick: () => {
						auth.signOut();
						navigate("/");
					},
				},
				{
					icon: "log out",
					text: t("actAsUserLogout", "Stop acting as user"),
					hidden: !actAsUser,
					onClick: () => {
						setActAsUser();
						navigate(`/admin/users/${actAsUser}`);
					},
				},
			],
		],
		[t, profile, hasRight, orgType, orgId, hasRole, actAsUser, publishersById, navigate],
	);
}
