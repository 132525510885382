import { Label } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

export default function OrderLabel({ order }) {
	const { t } = useTranslation();

	if (order.processed) {
		return <Label color="green">{t("paid", "Paid")}</Label>;
	}

	if (order.sent) {
		return <Label>{t("sent", "Sent")}</Label>;
	}

	return <Label color="blue">{t("pending", "Pending")}</Label>;
}
